/**
 * The AJAX interceptor client listens to and intercepts all `fetch` and `xhr` calls made on the site
 * and appends the authorization header to them with the JWT Bearer token (if one is available).
 *
 * @category Ajax Interceptor Client
 */
export interface AjaxInterceptor {
    readonly startup: () => void;
    readonly enable: () => void;
    readonly disable: () => void;
    readonly isEnabled: () => boolean;
    readonly setLogLevel: (logLevel: string) => void;
    readonly doesUrlMatchAnyEndpoints: (url: RequestInfo) => boolean;
    readonly addJwtToFetchHeader: (jwt: string, args: unknown[]) => void;
}

/**
 * @internal
 */
export class AjaxInterceptorImpl implements AjaxInterceptor {
    static REG_EXP_URL_MATCHER = /^api.*\.topdanmark\.(cloud|dk)$/;
    static REG_EXP_HOST_FROM_URL = /^((http[s]?):)?\/\/([^:/\s]+)/;

    private _enable: boolean;
    private _logLevel: string;
    private _getAuthToken: () => string | undefined;
    private _hasInitFetch: boolean;
    private _hasInitXMLHttpRequest = false;

    constructor(getAuthToken: () => string | undefined) {
        this._enable = true;
        this._logLevel = '';
        this._getAuthToken = getAuthToken;
        this._hasInitFetch = false;
        this._hasInitXMLHttpRequest = false;
    }

    static getInstance(getAuthToken: () => string | undefined): AjaxInterceptor {
        return new AjaxInterceptorImpl(getAuthToken);
    }

    public startup(): void {
        this.initFetch();
        this.initXMLHttpRequest();
    }

    public enable(): void {
        this._enable = true;
    }

    public disable(): void {
        this._enable = false;
    }

    public isEnabled(): boolean {
        return !!this._enable;
    }

    /**
     * set LogLevel, to disable set it to null or empty string
     * @param logLevel (string) debug, info, error
     */
    public setLogLevel(logLevel: string): void {
        this._logLevel = logLevel;
    }

    /**
     * Specialized method for this class to print logs to the console
     *
     * @private
     *
     * @param logLevel
     * @param logs
     *
     * @returns
     */
    private log(logLevel: string, ...logs: unknown[]) {
        if (typeof logLevel === 'undefined' || logLevel === null) {
            return;
        }

        let logStatements: string | Array<unknown>;
        if (logs.length === 1) {
            logStatements = `C9IntercepterAjax: ${logs[0]}`;
        } else {
            logStatements = logs;
        }

        if (this._logLevel === 'debug' && (logLevel === 'error' || logLevel === 'info')) {
            console.info(logStatements);
        } else if (this._logLevel === 'error' && logLevel === 'error') {
            console.error(logStatements);
        }
    }

    /**
     * Determines if the URL being invoked matches the regexes specified for URLS that should
     * have Authorization headers appended the requests.
     *
     * @param url The URL to check
     * @returns true or false depending on whether the URL matches
     */
    public doesUrlMatchAnyEndpoints(url: RequestInfo): boolean {
        if (typeof url === 'undefined' || url === null) {
            return false;
        }

        if (typeof url !== 'string' || url.length === 0) {
            this.log('error', 'first parameter of fetch is not a url, aborting...');
            return false;
        }

        const urlMatch = url.match(AjaxInterceptorImpl.REG_EXP_HOST_FROM_URL);

        if (typeof urlMatch !== 'undefined' && urlMatch !== null && urlMatch.length > 2) {
            const host = urlMatch[3];
            const result = host.match(AjaxInterceptorImpl.REG_EXP_URL_MATCHER);
            return result != null;
        }

        return false;
    }

    /**
     * Add jwt to header of fetch arguments
     *
     * @param jwt The jwt string to be added
     * @param args The fetch request headers
     */
    public addJwtToFetchHeader(jwt: string, args: unknown[]): void {
        // if the method was called without an object representing fetch options, create one here...
        if (args.length === 1) {
            args.push({});
        }

        // If arguments have 2 values but the 2nd one (fetch options) is undefined, assign it with an empty object instead
        if (!args[1]) {
            args[1] = {};
        }

        // retrieve the headers object in the fetch options, or create an empty object if it doesn't exist
        const currentHeaders: Record<string, unknown> = ((args[1] as Record<string, unknown>).headers ?? {}) as Record<
            string,
            unknown
        >;

        let currentAuthHeader: string | undefined;
        for (const key in currentHeaders) {
            if (key.toLowerCase().trim() === 'authorization') {
                currentAuthHeader = currentHeaders[key] as string;
            }
        }

        // if current authorization header is null, undefined or an empty string
        if (typeof currentAuthHeader === 'undefined' || currentAuthHeader === null || currentAuthHeader.length === 0) {
            currentHeaders['Authorization'] = `Bearer ${jwt}`;
        }

        (args[1] as Record<string, unknown>).headers = currentHeaders;
    }

    /**
     * hook into XMLHttpRequest to automatic add jwt header on url request in endpointList
     *
     * @private
     */
    private initFetch() {
        if ('fetch' in window && !this._hasInitFetch) {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const _self = this;

            this.log('info', 'fetch defined, hook in to it... 2');

            // fixes issue with native internet browser on Samsung
            const originalFetch = window.fetch;

            window.fetch = function (...args: unknown[]) {
                const input: RequestInfo = args[0] as RequestInfo;
                _self.log('debug', 'Process fetch - url=' + input);

                if (_self._enable && _self.doesUrlMatchAnyEndpoints(input)) {
                    const jwt = _self._getAuthToken();

                    if (typeof jwt !== 'undefined' && jwt !== null && jwt.length > 0) {
                        _self.addJwtToFetchHeader(jwt, args);
                        _self.log('debug', 'addJwtHeaderToFetch - url=' + input);
                    }
                }

                return originalFetch.apply(this, args as Parameters<typeof window.fetch>);
            };

            this._hasInitFetch = true;
        } else {
            this.log('debug', 'NO fetch to init or already init');
        }
    }

    /**
     * Hook into XML Http requests
     *
     * @private
     */
    private initXMLHttpRequest() {
        if ('XMLHttpRequest' in window && !this._hasInitXMLHttpRequest) {
            this.log('info', 'XMLHttpRequest defined, hook in to it...');

            type EncrichedXMLHttpRequest = XMLHttpRequest & {
                _interceptorRequestUrl: string;
                _topHasAuthorizationHeader: boolean;
            };

            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const _self = this;

            const originalOpen: typeof XMLHttpRequest.prototype.open = XMLHttpRequest.prototype.open;

            XMLHttpRequest.prototype.open = function (this: EncrichedXMLHttpRequest, ...args: unknown[]): void {
                const url: string = args[1] as string;

                // set request url on XMLHttpRequest, to use in send interceptor function
                this._interceptorRequestUrl = url;

                originalOpen.apply(this, args as Parameters<typeof originalOpen>);
            };

            (XMLHttpRequest.prototype as EncrichedXMLHttpRequest)._topHasAuthorizationHeader = false;

            const originalSetRequestHeader = XMLHttpRequest.prototype.setRequestHeader;

            XMLHttpRequest.prototype.setRequestHeader =
                // Important for this NOT TO BE an arrow function because we need the calling object's scope instead of the parent scope
                function (this: EncrichedXMLHttpRequest, ...args: unknown[]) {
                    const name = args[0] as string;
                    if (name?.toLowerCase().trim() === 'authorization') {
                        this._topHasAuthorizationHeader = true;
                    }

                    originalSetRequestHeader.apply(
                        this,
                        args as Parameters<typeof XMLHttpRequest.prototype.setRequestHeader>
                    );
                };

            const originalSend = XMLHttpRequest.prototype.send;

            XMLHttpRequest.prototype.send = function (this: EncrichedXMLHttpRequest, ...args: unknown[]): void {
                // const body = args[0] as Document | BodyInit | null | undefined;
                const url = this._interceptorRequestUrl;

                _self.log('debug', 'Process  XMLHttpRequest.prototype.send - url=' + url);

                if (_self._enable && _self.doesUrlMatchAnyEndpoints(url)) {
                    const jwt = _self._getAuthToken();

                    if (jwt && !this._topHasAuthorizationHeader) {
                        this.setRequestHeader('Authorization', `Bearer ${jwt}`);
                        _self.log('debug', 'addJwtToXMLHttpRequestHeader - url=' + url);
                    }
                }

                originalSend.apply(this, args as Parameters<typeof XMLHttpRequest.prototype.send>);
            };

            this._hasInitXMLHttpRequest = true;
        } else {
            this.log('debug', 'NO XMLHttpRequest to init');
        }
    }
}
